<template>
  <basic-modal @open="openModal" width="600" transition="slide-x-transition">
    <template #header>
      <divider
        title="add value"
        sub-title="let’s make it unique"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-6 px-md-14 mt-8 pb-6">
        <v-row class="ma-0">
          <v-col cols="12" class="px-0">
            <v-text-field
              class="no-error-msg"
              label="value"
              outlined
              v-model="form.name"
              dense
              color="SonicSilver"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-10 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn-cancel btn__size__18"
          text="CANCEL"
          icon="WMi-cancel-linear"
          height="40"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign btn__size__16 ml-5 site__button width-auto"
          text="add it"
          @click.native="addAttribute"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
  import {mapMutations} from 'vuex'
export default {
  name: "modal_add_attribute_value",
  data: () => ({
    form: {
      id: null,
      name: '',
      key: Math.random().toString(36).substring(2, 7),
    },
    attrKey: null,
    isEdit: false
  }),
  methods: {
    ...mapMutations('attribute', ['ADD_ATTRIBUTE_VALUE']),
    openModal({attributeValue, attrKey}) {
      if (attributeValue) {
        this.form = attributeValue
        this.isEdit = true
      } else {
        this.isEdit = false
      }
      if (attrKey) {
        this.attrKey = attrKey
      }
    },
    addAttribute() {  
      if(!this.form.name || !this.form.name.trim()){return}
      if (!this.isEdit) {
        this.ADD_ATTRIBUTE_VALUE({attributeKey: this.attrKey, attributeValue: this.form})
      }
      this.$_closeModal()
    }
  }
};
</script>
<style scoped>
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
