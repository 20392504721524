<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              backText="HOME"
              title="setting"
              sub-title="the configuration"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="pb-4">
          <v-col cols="12"><SettingItems /></v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import SettingItems from "@/components/Setting/Items.vue";
export default {
  components: {
    SettingItems,
  },
};
</script>
