<template>
  <basic-modal width="1200" transition="slide-x-transition">
    <template #header>
      <divider
        title="Model Special Attributes"
        sub-title="let’s make it unique"
        size="large"
      ></divider>
    </template>
    <template #default>
      <v-form ref="attributeForm">
        <div class="px-6 px-md-14 mt-8 pb-16">
          <div class="pb-1">
            <v-card
              outlined
              class="px-3 my-3"
              v-for="attr in getAttributes"
              :key="attr.key"
            >
              <v-row class="ma-0">
                <v-col cols="3" class="px-0">
                  <v-text-field
                    label="attributes category"
                    outlined
                    dense
                    color="SonicSilver"
                    v-model="attr.name"
                    :rules="attrRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="9" class="pr-0">
                  <v-chip
                    v-for="(attributeValue, index) in attr.attributeValues"
                    :key="index"
                    class="setting__chip py-5 mb-2 mr-1"
                    color="grey lighten-3"
                    label
                    text-color="black"
                  >
                    <span>{{attributeValue.name}}</span>
                    <v-btn class="ml-3" fab x-small depressed color="grey lighten-2" @click.native="$_openModal('add_attribute_value', {attributeValue, attrKey: attr.key})">
                      <v-icon color="black" >
                        WMi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn class="ml-3" fab x-small depressed color="grey lighten-2"  @click.native="removeAttrByIdx(attr.key, attributeValue.key)">
                      <v-icon color="black">
                        WMi-trash
                      </v-icon>
                    </v-btn>
                  </v-chip>
                  <v-btn
                    class="ml-3"
                    fab
                    x-small
                    depressed
                    color="grey lighten-3"
                    @click.native="$_openModal('add_attribute_value', {attrKey: attr.key})"
                  >
                    <v-icon color="black"> WMi-plus-linear </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-right mb-2">
                <block-button
                  text="remove"
                  height="23"
                  color="red"
                  bg-color="bgLightPink"
                  class="btn-remove"
                  @click.native="removeRowAttr(attr.key)"
                />
              </div>
            </v-card>
            <div class="text-right mr-3 mt-4">
              <block-button
                text="add"
                height="23"
                color="cyan"
                bg-color="bgCyan"
                class="btn-add px-7"
                @click.native="addAttributeRow"
              />
            </div>
          </div>
        </div>
      </v-form>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-10 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn-cancel btn__size__18"
          text="CANCEL"
          icon="WMi-cancel-linear"
          text-mode="text"
          height="40"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          class="btn__modal--assign btn__size__16 ml-5 site__button width-auto"
          text="save data"
          :loading="syncBtnLoading"
          @click.native="syncAttr"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import AttributeRepository from "@/abstraction/repository/attributeRepository";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
const attributeRepository = new AttributeRepository();
const BLANK_ATTRIBUTE = {
  id: null,
  name: "",
  attributeValues: [],
};
export default {
  name: "modal_model_special_attribute",
  data: () => ({
    items: ["value"],
    syncBtnLoading: false,
    comboboxItems: [],
  }),
  computed: {
    ...mapGetters('attribute', ['getAttributes']),
    attrRules(){
      return [
        v=>!!v || 'This field is required',
      ]
    }
  },
  methods: {
    ...mapActions("attribute", ["loadAttributes"]),
    ...mapMutations("attribute", [
      "DELETE_ATTRIBUTE",
      "DELETE_ATTRIBUTE_VALUE",
      "ADD_ATTRIBUTE",
    ]),
    addAttributeRow() {
      this.ADD_ATTRIBUTE({
        ...cloneDeep(BLANK_ATTRIBUTE),
        ...{ key: Math.random().toString(36).substring(2, 7) },
      });
    },
    removeRowAttr(key) {
      this.DELETE_ATTRIBUTE(key);
    },
    removeAttrByIdx(attributeKey, attributeValueKey) {
      this.DELETE_ATTRIBUTE_VALUE({ attributeKey, attributeValueKey });
    },
    async syncAttr() {
      try {
        if(!this.$refs.attributeForm.validate()){return}
        this.syncBtnLoading = true;
        await attributeRepository.sync({ attributes: this.getAttributes });
        this.$_closeModal();
      } catch (error) {
        return error;
      } finally {
        this.syncBtnLoading = false;
      }
    },
  },
  created() {
    this.loadAttributes();
  },
};
</script>
<style scoped>
.btn-remove,
.btn-add {
  font-family: "Montserrat-regular";
  font-size: 14px;
}
.setting__chip {
  font-family: "montserrat-light";
  font-size: 17px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
