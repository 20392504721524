<template>
  <div class="setting__card border rounded pa-4" @click="$_openModal('model_special_attribute')">
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center h-100" style="overflow: hidden">
          <div class="setting__box">
            <div class="setting__title">
              {{ setting.title }}
            </div>
            <small class="setting__text">{{ setting.text }}</small>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="d-flex align-center h-100 justify-content-end">
          <v-icon class="WMi-right-chevron ml-3" color="black"></v-icon>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    setting: {
      type: Object,
    },
  },
  data: () => ({}),
};
</script>
<style scoped>
.setting__card:hover {
  border: 1px solid var(--color-black) !important;
  cursor: pointer;
  transition: all 0.3s linear;
}
.setting__box {
  line-height: 14px;
}
.setting__title {
  font-family: "montserrat-regular";
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.setting__text {
  position: relative;
  font-family: "montserrat-light";
  font-size: 8px;
  /* top: -0.7rem;
  font-size: 12px; */
}
.setting__role {
  /* position: relative;
  top: -0.6rem; */
  line-height: 15px;
}
</style>
