<template>
  <list title="items" class="wa__large">
    <template>
      <hr class="mt-0" />
      <v-col cols="12" class="py-1 px-0" v-for="setting in settings" :key="setting.key">
        <SettingItem :setting="setting" />
      </v-col>
      <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
      <template>
        <div class="text-center">
          <ModelSpecialAttributeModal v-if="isModal('modal_model_special_attribute')" />
          <AddAttributeValue v-if="isModal('modal_add_attribute_value')" />
        </div>
      </template>
      <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
    </template>
  </list>
</template>
<script>
import SettingItem from "./Item.vue";
import ModelSpecialAttributeModal from "./Modal/ModelSpecialAttribute.vue";
import AddAttributeValue from "./Modal/AddAttributeValue.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SettingItem,
    ModelSpecialAttributeModal,
    AddAttributeValue,
  },
  data: () => ({
    settings: [
      {
        id: 1,
        title: "Model Special Attributes",
        text: "Various items can be attached to a model",
      },
    ],
  }),
  computed: {
    ...mapGetters("modal", ["isModal"]),
  },
};
</script>
<style scoped>
.wa__model__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}
.wa__model__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 2px;
}
.wa__add__new__project {
  text-align: right;
  right: 50px;
  bottom: 20px;
}
</style>
